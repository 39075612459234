import { useState, useEffect } from "react";
import env from "react-dotenv";
import ProcurerTable from './ProcurerTable';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';

function Procurers() {

  const [tableItems, setTableItems] = useState([]);
  const [email, setEmail] = useState('');
  const [product, setProduct] = useState('');
  const [procurerCreate, setProcurerCreate] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const auth = useAuth0();

  const [permissions] = useOutletContext();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const create = async () => {
    setLoading(true);
    setProcurerCreate(false);
    setValidEmail(true);
    if (!/\S+@\S+\.\S+/.test(email)) {
      setValidEmail(false);
      setLoading(false);
    } else {
      const accessToken = await auth.getAccessTokenSilently({
        authorizationParams: {
          audience: env.AUTH0_AUDIENCE,
          scope: "read:current_user",
        },
      });
      const procurer = await fetch(env.AUTH0_API_SERVER+"procurers", {
        headers: {
          "Content-Type":"application/json",
          "Authorization": "Bearer "+accessToken,
        },
        method: 'post',
        body: JSON.stringify({
          email: email,
          product: product,
        }),
      }).then((res) => res.json());
      const localTableItems = [...tableItems];
      localTableItems.push(procurer);
      setTableItems(localTableItems);
      setProcurerCreate(true);
      setEmail('');
      setProduct('');
      setLoading(false);
    }
  };

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      const accessToken = await auth.getAccessTokenSilently({
        authorizationParams: {
          audience: env.AUTH0_AUDIENCE,
          scope: "read:current_user",
        },
      });
      const procurers = await fetch(env.AUTH0_API_SERVER+"procurers", {
        headers: {
          "Content-Type":"application/json",
          "Authorization": "Bearer "+accessToken,
        },
      }).then((res) => res.json());
      setTableItems(procurers);
      setLoading(false);
    };
    initData();
  }, [auth])

  return (
    <section id="procurers">
      {!loading && permissions?.findIndex((x) => x.permission_name === 'read:procurers') >= 0 && tableItems.length > 0 && <div className="affiliation-section">
        <ProcurerTable rowsPerPage={isMobile ? '5' : '10'} data={tableItems} permissions={permissions} />
      </div>}
      {!loading && permissions?.findIndex((x) => x.permission_name === 'manage:procurers') >= 0 && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('create')}</h2>
        <table className="table">
          <tbody>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('email')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('product')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={product} onChange={(e) => setProduct(e.target.value)} />
              </td>
            </tr>
          </tbody>
        </table>
        {!validEmail && <small>t('valid_email')</small>}
        <div className="mt-3">
          <button disabled={!email || !product} className="btn btn-lg item-button" onClick={() => confirm(() => create())}>{t('confirm')}</button>
        </div>
        {procurerCreate && <small>{t('created_procurer')}</small>}
      </div>}
      {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
    </section>
  );
}

export default Procurers;
