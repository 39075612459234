import { useState, useEffect } from "react";
import env from "react-dotenv";
import Layout from './Layout';
import LayoutManagement from './LayoutManagement';
import Public from './Public';
import Home from './Home';
import Store from './Store';
import Service from './Service';
import Services from './Services';
import Notifications from './Notifications';
import Chats from './Chats';
import Users from './Users';
import Checkout from './Checkout';
import Tokens from './Tokens';
import Session from './Session';
import Stores from './Stores';
import Procurers from './Procurers';
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {

  const [loading, setLoading] = useState(false);

  const auth = useAuth0();

  useEffect(() => {
    const initData = async () => {
      if (!window.sessionStorage.getItem('services')) {
        setLoading(true);
        const accessToken = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: env.AUTH0_AUDIENCE,
            scope: "read:current_user",
          },
        });
        const services = await fetch(env.AUTH0_API_SERVER+"services", {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        window.sessionStorage.setItem('services', JSON.stringify(services));
        setLoading(false);
      }
    };
    if (auth.isAuthenticated) {
      initData();
    }
  }, [auth]);

  return (
    <section id="app">
      {!loading && <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            {!auth.isAuthenticated && <Route path="/" element={<Public />} />}
            {auth.isAuthenticated && <Route path="/" element={<Home />} />}
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/session" element={<Session />} />
            {auth.isAuthenticated && <Route path="/tokens" element={<Tokens />} />}
            {auth.isAuthenticated && <Route path="/store" element={<Store />} />}
            {auth.isAuthenticated && <Route path="/services" element={<Services />} />}
            {auth.isAuthenticated && <Route path="/notifications" element={<Notifications />} />}
            {auth.isAuthenticated && <Route path="/chats" element={<Chats />} />}
            {auth.isAuthenticated && <Route path="/users" element={<Users />} />}
            {auth.isAuthenticated && window.sessionStorage.getItem('services') && JSON.parse(window.sessionStorage.getItem('services')).map((el, k) => (
              <Route key={k} path={'/'+el.code} element={<Service service={el} />} />
            ))}
          </Route>
          <Route element={<LayoutManagement />}>
            {auth.isAuthenticated && <Route path="/stores" element={<Stores />} />}
            {auth.isAuthenticated && <Route path="/procurers" element={<Procurers />} />}
          </Route>
        </Routes>
      </BrowserRouter>}
      {loading && <div className="mt-5 d-flex align-items-center justify-content-center">
        <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>
      </div>}
    </section>
  );
}

export default App;
