import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';

function Users() {

  const [membership, setMembership] = useState({});
  const [store, setStore] = useState({});
  const [cost, setCost] = useState(0);
  const [page, setPage] = useState(1);
  const [range, setRange] = useState([]);
  const [slice, setSlice] = useState([]);
  const [email, setEmail] = useState('');
  const [admin, setAdmin] = useState(0);
  const [userCreate, setUserCreate] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const auth = useAuth0();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      text: cost > 0 ? t('operation_cost', {cost: cost}) : '',
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const requestUser = async () => {
    setLoading(true);
    setUserCreate(false);
    setValidEmail(true);
    if (!/\S+@\S+\.\S+/.test(email)) {
      setValidEmail(false);
      setLoading(false);
    } else {
      const password = generatePassword();
      const accessToken = await auth.getAccessTokenSilently({
        authorizationParams: {
          audience: env.AUTH0_AUDIENCE,
          scope: "read:current_user",
        },
      });
      await fetch(env.AUTH0_API_SERVER+"request-user", {
        headers: {
          "Content-Type":"application/json",
          "Authorization": "Bearer "+accessToken,
        },
        method: 'post',
        body: JSON.stringify({
          store: store.code,
          email: email,
          password: password,
          lang: 'it',
          admin: admin,
        }),
      }).then((res) => res.json());
      const localStore = {...store};
      localStore.tokens -= cost;
      setStore(localStore);
      setUserCreate(true);
      setEmail('');
      setAdmin(0);
      setLoading(false);
    }
  };

  const generatePassword = () => {
    const lowerCasedAlphabets = [..."abcdefghijklmnopqrstuvwxyz".split("")];
    const upperCasedAlphabets = lowerCasedAlphabets.map((alphabet) => alphabet.toUpperCase());
    const numbers = [..."1234567890".split("")];
    const symbols = [..."!#$%&@*".split("")];
    const password = [];
    for (let i=0; i<5; i++) {
      password.push(lowerCasedAlphabets[Math.floor(Math.random() * lowerCasedAlphabets.length)]);
    }
    for (let i=0; i<3; i++) {
      password.push(upperCasedAlphabets[Math.floor(Math.random() * upperCasedAlphabets.length)]);
    }
    for (let i=0; i<2; i++) {
      password.push(numbers[Math.floor(Math.random() * numbers.length)]);
    }
    for (let i=0; i<2; i++) {
      password.push(symbols[Math.floor(Math.random() * symbols.length)]);
    }
    return password.sort(() => Math.random() - 0.5).join('');
  };

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('store')) {
        setLoading(true);
        const accessToken = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: env.AUTH0_AUDIENCE,
            scope: "read:current_user",
          },
        });
        const priceList = await fetch(env.AUTH0_API_SERVER+"price-lists/user", {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setCost(priceList.cost);
        const localMembership = await fetch(env.AUTH0_API_SERVER+"membership/"+window.sessionStorage.getItem('store'), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setMembership(localMembership);
        const localStore = await fetch(env.AUTH0_API_SERVER+"info/"+window.sessionStorage.getItem('store'), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setStore(localStore);
        const users = (await fetch(env.AUTH0_API_SERVER+"users/"+window.sessionStorage.getItem('store'), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json())).filter((x) => x.identities.findIndex((y) => y.provider === 'auth0') >= 0);
        const rowsPerPage = isMobile ? 5 : 10;
        const localRange = [];
        for (let i = 1; i <= Math.ceil(users.length / rowsPerPage); i++) {
          localRange.push(i);
        }
        setRange([...localRange]);
        const localSlice = users.slice((page - 1) * rowsPerPage, page * rowsPerPage);
        setSlice([...localSlice]);
        if (localSlice.length < 1 && page !== 1) {
          setPage(page - 1);
        }
        setLoading(false);
      }
    };
    initData();
  }, [auth, page])

  return (
    <section id="users">
      {!loading && membership?.role === 'admin' && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('users')}</h2>
        <div className="mt-5">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">{t('email')}</th>
                <th scope="col">{t('creation')}</th>
              </tr>
            </thead>
            {slice.length > 0 && <tbody>
              {slice.map((el, k) => (
                <tr key={k}>
                  <td>
                    <span>{el.email}</span>
                  </td>
                  <td style={{width: '200px',}}>
                    <span>{new Date(el.created_at).toDateString()}</span>
                  </td>
                </tr>
              ))}
            </tbody>}
          </table>
        </div>
        {slice.length === 0 && <span>{t('no_users')}</span>}
        <div>
          {range.map((el, index) => (
            (page === el || page === el-1 || page === el+1 || index === 0 || index === range.length-1) && <button key={index} className={page === el ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => setPage(el)}>{(page === el+1 && index !== 0) || (page === el-1 && index !== range.length-1) ? '...' : el}</button>
          ))}
        </div>
      </div>}
      {!loading && membership?.role === 'admin' && store?.tokens >= cost && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('request_user')}</h2>
        {cost > 0 && <p>{t('operation_cost', {cost: cost})}</p>}
        <table className="table">
          <tbody>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('email')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('role')}</p>
              </td>
              <td>
                <select className="affiliation-input" value={admin} onChange={e => setAdmin(e.target.value)}>
                  <option value="0">{t('base_user')}</option>
                  <option value="1">{t('admin_user')}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        {!validEmail && <small>{t('valid_email')}</small>}
        <div className="mt-3">
          <button disabled={!email} className="btn btn-lg item-button" onClick={() => confirm(() => requestUser())}>{t('confirm')}</button>
        </div>
        {userCreate && <small>{t('created_user')}</small>}
      </div>}
      {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
    </section>
  );
}

export default Users;
