import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

function StoreTable(props) {

  const [page, setPage] = useState(1);
  const [range, setRange] = useState([]);
  const [slice, setSlice] = useState([]);
  const [tokens, setTokens] = useState(0);
  const [updating, setUpdating] = useState(-1);

  const { t } = useTranslation();

  const auth = useAuth0();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const update = async (index, tokens) => {
    setUpdating(-1);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"stores/"+slice[index].code, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        tokens: tokens,
        name: slice[index].name,
      }),
    }).then((res) => res.json());
    const localSlice = [...slice];
    localSlice[index].tokens = tokens;
    setSlice(localSlice);
  };

  const remove = async (index) => {
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"stores/"+slice[index].code, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'delete',
    }).then((res) => res.json());
    const localSlice = [...slice];
    localSlice[index].splice(index, 1);
    setSlice(localSlice);
  };

  const toggleUpdate = (index) => {
    setUpdating(index);
    setTokens(slice[index].tokens);
  }

  useEffect(() => {
    const initData = async () => {
      const localRange = [];
      for (let i = 1; i <= Math.ceil(props.data.length / props.rowsPerPage); i++) {
        localRange.push(i);
      }
      setRange([...localRange]);
      const localSlice =  props.data.slice((page - 1) * props.rowsPerPage, page * props.rowsPerPage);
      setSlice([...localSlice]);
      if (localSlice.length < 1 && page !== 1) {
        setPage(page - 1);
      }
    };
    initData();
  }, [page, props.data, props.rowsPerPage]);

  return (
    <section id="store-table">
      <h2 className="affiliation-subtitle">{t('stores')}</h2>
      <div className="mt-5">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t('code')}</th>
              <th scope="col">{t('name')}</th>
              <th scope="col">{t('tokens')}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          {slice.length > 0 && <tbody>
            {slice.map((el, k) => (
              <tr key={k}>
                <td style={{width: '150px',}}>
                  <span>{el.code}</span>
                </td>
                <td>
                  <span>{el.name}</span>
                </td>
                {props.permissions?.findIndex((x) => x.permission_name === 'manage:stores') >= 0 && <td style={{width: '150px',}}>
                  {updating !== k && <span>{el.tokens} </span>}
                  {updating !== k && <button className="btn btn-sm item-button" onClick={ () => toggleUpdate(k) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('update')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                    </svg>
                  </button>}
                  {updating === k && <input className="affiliation-input" value={tokens} onChange={(e) => setTokens(e.target.value)} />}
                  {updating === k && <button disabled={!tokens} className="btn btn-sm item-button-success" onClick={ () => update(k, tokens) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('update')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                    </svg>
                  </button>}
                </td>}
                {props.permissions?.findIndex((x) => x.permission_name === 'manage:stores') >= 0 && <td style={{width: '50px',}}>
                  <button className="btn btn-sm item-button-danger" onClick={ () => confirm(() => remove(k)) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('remove')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                    </svg>
                  </button>
                </td>}
              </tr>
            ))}
          </tbody>}
        </table>
      </div>
      {slice.length === 0 && <span>{t('no_stores')}</span>}
      <div>
        {range.map((el, index) => (
          (page === el || page === el-1 || page === el+1 || index === 0 || index === range.length-1) && <button key={index} className={page === el ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => setPage(el)}>{(page === el+1 && index !== 0) || (page === el-1 && index !== range.length-1) ? '...' : el}</button>
        ))}
      </div>
    </section>
  );
}

export default StoreTable;
