import { useTranslation } from 'react-i18next';

function Public() {

  const { t } = useTranslation();

  return (
    <section id="public">
      <div className="affiliation-section">
        <div className="mb-5">
          <p>{t('app_intro')}</p>
        </div>
      </div>
    </section>
  );
}

export default Public;
