import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import {loadStripe} from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(env.STRIPE_KEY);

function Checkout() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [storeName, setStoreName] = useState('');
  const [address, setAddress] = useState({});
  const [product, setProduct] = useState(null);
  const [secret, setSecret] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const startSession = async () => {
    setLoading(true);
    setValidEmail(true);
    if (!/\S+@\S+\.\S+/.test(email)) {
      setValidEmail(false);
      setLoading(false);
    } else if (!/^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(password)) {
      setValidPassword(false);
      setLoading(false);
    } else {
      const session = await fetch(env.AUTH0_API_SERVER+"session", {
        headers: {
          "Content-Type":"application/json",
        },
        method: 'post',
        body: JSON.stringify({
          product: product.id,
          email: email,
          password: password,
          lang: window.localStorage.getItem('lang'),
          name: storeName,
          address: address,
          return_url: window.location.origin+'/session?session_id={CHECKOUT_SESSION_ID}',
        }),
      }).then((res) => res.json());
      setSecret(session.client_secret);
      setStoreName('');
      setEmail('');
      setLoading(false);
    }
  };

  //const generatePassword = () => {
  //  const specials = '!@#$%^&*';
  //  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  //  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  //  const numbers = '0123456789';
  //  const all = specials + lowercase + uppercase + numbers;
  //  const password = [];
  //  password.push(specials.charAt(Math.floor(Math.random() * specials.length)));
  //  password.push(numbers.charAt(Math.floor(Math.random() * numbers.length)));
  //  password.push(uppercase.charAt(Math.floor(Math.random() * uppercase.length)));
  //  password.push(lowercase.charAt(Math.floor(Math.random() * lowercase.length)));
  //  for (let i=0; i < 8; i++) {
  //    password.push(all.charAt(Math.floor(Math.random() * all.length)));
  //  }
  //  return password.sort((a, b) => Math.random() - 0.5).join('');
  //};

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      if (searchParams.get('product_id')) {
        const localProduct = await fetch(env.AUTH0_API_SERVER+"products/"+searchParams.get('product_id'), {
          headers: {
            "Content-Type":"application/json",
          },
        }).then((res) => res.json());
        setProduct(localProduct);
      }
      setLoading(false);
    };
    initData();
  }, [searchParams])

  return (
    <section id="checkout">
      {product?.id && <div>
        {!loading && !secret && <div className="affiliation-section">
          <div className="affiliation-product-selected">
            <h4>{product.description}</h4>
            <h2>{(product.price*1).toFixed(2)} € <small>{product.frequency === 'yearly' ? ' / anno' : (product.frequency === 'monthly' ? ' / mese' : '')}</small></h2>
          </div>
          <table className="table">
            <tbody>
              <tr>
                <td style={{width: '100px',}}>
                  <p>{t('store')}</p>
                </td>
                <td>
                  <input className="affiliation-input" type="text" value={storeName} onChange={(e) => setStoreName(e.target.value)} />
                </td>
              </tr>
              <tr>
                <td style={{width: '100px',}}>
                  <p>{t('email')}</p>
                </td>
                <td>
                  <input className="affiliation-input" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                </td>
              </tr>
              <tr>
                <td style={{width: '100px',}}>
                  <p>{t('password')}</p>
                </td>
                <td>
                  <input className="affiliation-input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </td>
              </tr>
              <tr>
                <td style={{width: '100px',}}>
                  <p>{t('address')}</p>
                </td>
                <td>
                  <Autocomplete className="affiliation-input" defaultValue={address?.formatted_address} apiKey={env.GMAP_KEY} language="it" onPlaceSelected={(place) => setAddress(place)} options={{types: []}} />
                </td>
              </tr>
            </tbody>
          </table>
          {!validEmail && <small>{t('valid_email')}</small>}
          {!validPassword && <small>{t('valid_password')}</small>}
          <div className="mt-3">
            <button disabled={!storeName || !email || !password || !address?.formatted_address || !product} className="btn btn-lg item-button" onClick={() => startSession()}>{t('buy')}</button>
          </div>
        </div>}
        {!loading && secret && <EmbeddedCheckoutProvider stripe={stripePromise} options={{clientSecret: secret}}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>}
        {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
      </div>}
    </section>
  );
}

export default Checkout;
